<template>
  <div>
    <Header />
    <div class="mobile-width d-flex justify-content-between align-items-center">
      <div v-if="balai !== 9" class="bwsTitle mobile-top" :class="{ 'darkModeWhiteCl': isDarkMode }"
        style="text-transform: uppercase;">
        {{ $app_title }}
      </div>
      <div v-if="balai === 9" class="bwsTitle mobile-top" :class="{ 'darkModeWhiteCl': isDarkMode }"
        style="text-transform: uppercase;">
        <div class="d-flex align-items-center text-uppercase mt-1">
          <img :src="logoMalang" style="width: 20px;" />
          <span class="mx-2">PERUMDA TIRTA KANJURUHAN</span>
        </div>
      </div>


    </div>
    <div class="mobile-width">
      <div class="d-flex justify-content-between align-items-center">
        <div class="subTitle" :class="{ 'darkModeWhiteCl': isDarkMode }">Daftar Pos</div>
        <div>
          <router-link v-if="role === 'is_superuser'" :to="{ name: 'AddStation' }" type="button"
            class="btn btn-primary float-right my-2" style="padding: 5px 10px">Tambah Data</router-link>
        </div>
      </div>
      <dataset v-slot="{ ds }" :ds-data="stations" :ds-sortby="sortBy" :ds-search-in="['station_name', 'balai']">
        <div :data-page-count="ds.dsPagecount">
          <dataset-search ds-search-placeholder="Search..." :class="{ 'darkMode': isDarkMode }" />
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-hover table-responsive text-nowrap text-center table-borderless bg-white"
                :class="{ 'darkMode': isDarkMode }">
                <thead class="table-light" :class="{ 'darkerMode': isDarkMode }">
                  <tr>
                    <th scope="col" :class="{ 'darkerMode': isDarkMode }">#</th>
                    <th v-for="(th, index) in cols" :key="th.field"
                      :class="['sort', th.sort, { 'darkerMode': isDarkMode }]" @click="click($event, index)"
                      class="thLight">
                      {{ th.name }} <i class="gg-select float-right"></i>
                    </th>
                  </tr>
                </thead>
                <div v-if="loading_i" class="d-flex flex-column justify-content-center align-items-center">
                  <i class="zmdi zmdi-spinner zmdi-hc-spin" style="font-size: 1.5rem"></i>
                </div>
                <dataset-item tag="tbody">
                  <template #default="{ row, rowIndex }">
                    <tr :class="{ 'darkMode': isDarkMode }">
                      <td scope="row">{{ rowIndex + 1 }}</td>
                      <td>{{ row.station_name }}</td>

                      <td v-if="role == 'is_superuser'">
                        <span v-for="item in balais" :key="item.id">
                          <span v-if="item.id == row.balai">
                            {{ item.balai }}
                          </span>
                        </span>
                      </td>
                      <td>{{ row.location }}</td>
                      <td>{{ row.latitude }}</td>
                      <td>{{ row.longitude }}</td>
                      <td>
                        <router-link type="button" class="btn btn-primary btn-sm mx-1" :to="{
                          path: '/station/data/' + row.id + '/1',
                        }">Data</router-link>
                        <span v-if="role == 'is_staff' || role == 'is_superuser'">
                          <router-link type="button" class="btn btn-success btn-sm mx-1"
                            :to="`/station/update/${balai}/${row.id}`">Edit</router-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </dataset-item>
              </table>
            </div>
          </div>
        </div>
        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
          <dataset-info class="mb-2 mb-md-0" />
          <dataset-pager />
        </div>
      </dataset>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import axios from "axios";
import logoMalang from "@/assets/img/logo_malang.png";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  // DatasetShow,
} from "vue-dataset";

export default {
  name: "Station",
  components: {
    Header,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    // DatasetShow,
  },
  data: function () {
    return {
      logoMalang,
      stations: [],
      loading_i: true,
      balais: [],
      role: null,
      balai: null,
      nama_pos: "",
      cols: [
        {
          name: "Nama Pos",
        },
        {
          name: "Lokasi",
        },
        {
          name: "Latitude",
        },
        {
          name: "Longitude",
        },

        {
          name: "Action",
        },
      ],
      colSuper: [
        {
          name: "Nama Pos",
        },
        {
          name: "Balai",
        },
        {
          name: "Lokasi",
        },
        {
          name: "Latitude",
        },
        {
          name: "Longitude",
        },

        {
          name: "Action",
        },
      ],
    };
  },
  computed: {
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  methods: {
    click(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    async loadData() {
      await axios
        .get(`${this.$baseURL}/station-sensor/${this.balai}`, {
          headers: {
            Authorization: `Token ${this.token}`,
          },
        })
        .then((r) => {
          this.stations = r.data;
          if (r.status == 200) {
            this.loading_i = false;
          }
        });
    },
    lAuthStation() {
      this.extractUserInfo();
      this.user_id =
        this.user &&
        this.user.profile &&
        this.user.profile.user &&
        this.user.profile.user.id;
      if (typeof this.user !== "object") {
        localStorage.clear();
        this.$router.push({ name: "Login" });
      }
      if (this.role === "is_superuser") {
        this.balai = 0;
        // this.balai = 1;
      }
    },
  },
  created() {
    this.lAuthStation();
    this.loadData();
    if (this.role == "is_superuser") {
      this.cols = this.colSuper;
    }
  },
  mounted() {
    this.gBalai();
  },
};
</script>

<style scoped src="@/assets/css/figma.css"></style>
