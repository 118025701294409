<template>
  <div class="container" style="padding: 0">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  watch: {
    isDarkMode: {
      immediate: true, // Run the handler immediately after the component is created
      handler(newVal) {
        this.setBodyBackgroundColor(newVal);
      },
    },
  },
  mounted() {
    this.$store.dispatch("startInactivityTimer");
    ["mousemove", "keydown", "mousedown", "touchstart"].forEach((event) => {
      window.addEventListener(event, this.handleUserActivity);
    });
  },
  beforeDestroy() {
    ["mousemove", "keydown", "mousedown", "touchstart"].forEach((event) => {
      window.removeEventListener(event, this.handleUserActivity);
    });
    this.$store.dispatch("clearInactivityTimer");
  },
  methods: {
    handleUserActivity() {
      this.$store.dispatch("resetInactivityTimer");
    },
    setBodyBackgroundColor(isDarkMode) {
      if (isDarkMode) {
        document.body.style.backgroundColor = '#3a509a';
        document.documentElement.style.backgroundColor = '#3a509a';
      } else {
        document.body.style.backgroundColor = ''; // Reset to default
        document.documentElement.style.backgroundColor = ''; // Reset to default
      }
    },
  },
};
</script>

<style>
html, body{
  padding: 0;
  margin: 0;
  background-color: #f4f7ff;
}

@media (min-width: 1280px) {
  .container,
  .container-fluid {
    max-width: 3840px !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.w-97 {
  max-width: 90%;
  margin-left: 90px;
}

a,
u {
  text-decoration: none !important;
}

.box {
  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.box:hover {
  box-shadow: 0 0 21px rgba(33, 33, 33, 0.2);
}

.box-sm {
  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.box-sm:hover {
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.2);
}

.bwsTitle {
  color: #092581;
  font-size: 1.3rem;
  font-weight: 500;
}

.bwsCl {
  color: #092581;
}

:root {
  --dark-bg: #3a509a;
  --darker-bg: #334687;
  --green-bg: #3a809a;
  --white-clr: #fff;
}

/* Base dark mode styles */
.darkMode, .darkerMode {
  color: var(--white-clr) !important;
}

/* Dark mode variations */
.darkMode {
  background: var(--dark-bg) !important;
}

.darkerMode {
  background: var(--darker-bg) !important;
}

/* Specific dark mode styles */
.darkModeGreenBg, .darkModeGreenCl {
  background: var(--green-bg) !important;
}

.darkModeWhiteCl {
  color: var(--white-clr) !important;
}

.darkModeBorderDash {
  border: 2px dashed var(--green-bg) !important;
}


.greenCard {
  background: #46dddd;
  border-radius: 15px;
}

.subTitle {
  font-size: 1.5rem;
  font-weight: 500;
}
/* ---new file */
</style>