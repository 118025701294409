<template>
  <label class="switch">
    <input type="checkbox" :checked="darkMode" @change="toggleDarkMode" />

    <span class="slider round d-flex justify-content-center" :style="{ backgroundColor: darkMode ? '#2196f3' : 'rgba(255, 255, 255, 0.11)' }">
      <img :src="darkMode_i" :style="{ filter: darkMode ? 'invert(100%)' : 'invert(0%)', width: '1.5rem' }" />
    </span>
  </label>
</template>

<script>
import darkMode_i from "@/assets/icons/darkMode.svg";

export default {
  computed: {
    darkMode: {
      get() {
        return this.$store.getters.isDarkMode;
      },
      set(value) {
        this.$store.dispatch('toggleDarkMode', value);
      },
    },
  },
  data() {
    return {
      darkMode_i
    }
  },
  methods: {
    toggleDarkMode(event) {
      this.darkMode = event.target.checked;
    },
  },
};
</script>

<style scoped>
/* Add styles for the toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 25%;
}

.slider:before {
  position: absolute;
  content: '';
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 25%;
  width: 32px;
}

.slider.round:before {
  border-radius: 25%;
}
</style>
