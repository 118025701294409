<template>
  <div v-if="stations[0]">
    <div class="card box-sm mt-2 comShadow" style="box-shadow: 10px; border-radius: 10px"
      :class="{ 'darkMode': isDarkMode }">
      <div class="row">
        <div class="col-md-4 p-0 imgSZ">
          <img :src="`${$baseURL}${stations[0].image}`" class="img-fluid" alt="test"
            style="object-fit: contain; border-radius: 10px 0px 0px 10px; width: 90%; margin-left: 12px" />
        </div>
        <div class="col-md-8 my-1 p-0">
          <div class="d-flex align-items-center mx-2">
            <div class="col-md-10 col-10">
              <div style="font-weight: 500; font-size: 1.2rem">
                {{ stations[0].station_name }}
              </div>
            </div>
            <div class="col-md-2 col-2 d-flex justify-content-end">
              <div v-for="station in status" :key="station[0].id">
                <div v-if="station[0].id == stations[0].id">
                  <span v-if="station[1].table.siaga == []"> </span>
                  <span v-else-if="station[1].table.siaga == 'MAINTENANCE'">
                    <img :src="mtc_i" class="statSZ" />
                  </span>
                  <span v-else-if="station[1].table.siaga == 'SIAGA 1'">
                    <img :src="s1_i" class="statSZ" />
                  </span>
                  <span v-else-if="station[1].table.siaga == 'SIAGA 2'">
                    <img :src="s2_i" class="statSZ" />
                  </span>
                  <span v-else-if="station[1].table.siaga == 'SIAGA 3'">
                    <img :src="s3_i" class="statSZ" />
                  </span>
                  <span v-else-if="station[1].table.siaga == 'OK'">
                    <img :src="ok_i" class="statSZ" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="balai == 6 || balai == 9" class="row">
            <div class="col-md-5 mx-0">
              <div class="m-1">
                <div class="info-label">Lokasi</div>
                <div class="info-value">{{ stations[0].location }}</div>
              </div>
              <div class="m-1">
                <div class="info-label">Pompa</div>
                <div class="info-value">{{ getNote(stations[0].note)[0] }} pompa, {{ getNote(stations[0].note)[1] }} l.s
                </div>
                <div class="info-value">Elmo {{ getNote(stations[0].note)[2] }} kW</div>
              </div>
            </div>

            <div class="col-md-7 mx-0 p-0">
              <div class="my-1 mx-2">
                <div class="info-label">Operasional 24 Jam</div>
                <div class="info-value">{{ getNote(stations[0].note)[3] }} pompa</div>
              </div>
              <div class="my-1 mx-2">
                <div class="info-label">Supply Pompa</div>
                <div class="info-value">{{ getNote(stations[0].note)[4] }}</div>
                <div class="info-value">{{ getNote(stations[0].note)[5] }}</div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-md-6 mx-0">
              <div class="my-1 mx-2">
                <div class="info-label">Lokasi</div>
                <div class="info-value">{{ stations[0].location }}</div>
                <div class="info-value" v-if="stations[0].station_type == 2 && stations[0].sungai != null">
                  {{ stations[0].sungai }}
                </div>
                <div class="text-white" v-else-if="stations[0].station_type == 2 && stations[0].sungai == null">
                  .
                </div>
              </div>
              <div class="my-1 mx-2">
                <div class="info-dummy text-white" v-if="stations[0].station_type != 2">
                  .
                </div>
                <div class="info-label">Latitude</div>
                <div class="info-value">
                  {{ stations[0].latitude }}<span>&#176;</span>
                </div>
              </div>
            </div>

            <div class="col-md-6 mx-0">
              <div class="my-1 mx-2">
                <div class="info-label">Elevasi</div>
                <div class="info-value">{{ stations[0].elevasi }} m</div>
                <div class="info-dummy">.</div>
              </div>
              <div class="my-1 mx-2">
                <div class="info-label">Longitude</div>
                <div class="info-value">
                  {{ stations[0].longitude }}<span>&#176;</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ava_width > 768">
      <div v-if="!stations[1][0].chart.mix_status[0]">
        <div v-if="stations[1][0].chart.chart_sensor.flat().length == 1">
          <div v-for="card in [stations[1]]" :key="card.id">
            <div class="bg-white text-center comShadow" style="border-radius: 15px; margin-left: 12px">
              <div v-for="(item, index) in chart_f2(
                    card[0].chart.mix_status,
                    card[0].chart.chart_sensor,
                    card[0].chart.array_act_chart_type,
                    card[0].chart.chart_label,
                    card[0].chart.chart_data,
                    card[0].chart.array_act_icon,
                    card[0].chart.array_act_symbol
                  )" :key="'C' + index">
                <div>
                  <div class="d-flex row my-2" :class="{ 'darkMode': isDarkMode }">
                    <div class="col-md-6 text-white p-2 greenCard" style="border-radius: 15px"
                      :class="{ 'darkModeGreenBg': isDarkMode }">
                      <div class="mb-1 iconSZ">
                        <img :src="`${$baseURL}${item.chart_icon}`" height="85" />
                      </div>
                      <div class="fw-bold" style="font-size: 1.2rem">
                        <div>{{ item.sensor[0][0] }}</div>
                      </div>
                      <div style="font-size: 0.8rem">
                        {{ formatDate(card[0].chart.chart_date) }},
                        {{ formatTime(card[0].chart.chart_date) }}
                      </div>
                    </div>
                    <div class="col-md-6 col d-flex align-items-center justify-content-center"
                      style="color: #46dddd; border-radius: 55px">
                      <p class="fw-bold mt-2 mx-2" style="font-size: 2.5rem">
                        {{ item.chart_data[0].slice(-1)[0] }}
                      </p>
                      <span style="font-size: 1.5rem">{{
                        item.symbol[0][0]
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stations[1][0].chart.chart_sensor.flat().length == 2">
          <div v-for="card in [stations[1]]" :key="card.id">
            <div class="d-flex justify-content-center align-content-around flex-wrap data align-items-center">
              <div v-for="(item, index) in chart_f2(
                card[0].chart.mix_status,
                card[0].chart.chart_sensor,
                card[0].chart.array_act_chart_type,
                card[0].chart.chart_label,
                card[0].chart.chart_data,
                card[0].chart.array_act_icon,
                card[0].chart.array_act_symbol
              )" :key="'C' + index" class="my-2">

                <div class="text-center comShadow mx-1" style="background: #fff; border-radius: 15px">
                  <div class="text-white p-2" style="
                    background: #46dddd;
                    border-radius: 15px;
                    min-width: 19vw;
                  ">
                    <div>
                      <img :src="`${$baseURL}${item.chart_icon[0]}`" height="70" />
                    </div>
                    <div class="fw-bold mt-1">
                      <div>{{ item.sensor[0][0] }}</div>
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ formatDate(card[0].chart.chart_date) }},
                      {{ formatTime(card[0].chart.chart_date) }}
                    </div>
                  </div>
                  <div style="color: #46dddd">
                    <p class="p-0 m-0">
                      <span class="fw-bold mx-1" style="font-size: 2rem">{{
                        item.chart_data[0].slice(-1)[0]
                      }}</span>
                      <span style="font-size: 1rem">{{ item.symbol[0][0] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stations[1][0].chart.chart_sensor.flat().length == 3">
          <div v-for="card in [stations[1]]" :key="card.id">
            <div class="d-flex justify-content-center align-content-around flex-wrap data align-items-center">
              <div v-for="(item, index) in chart_f2(
                card[0].chart.mix_status,
                card[0].chart.chart_sensor,
                card[0].chart.array_act_chart_type,
                card[0].chart.chart_label,
                card[0].chart.chart_data,
                card[0].chart.array_act_icon,
                card[0].chart.array_act_symbol
              )" :key="'C' + index" class="my-2">
                <div class="text-center comShadow mx-1" style="background: #fff; border-radius: 15px">
                  <div class="text-white p-2" style="
                    background: #46dddd;
                    border-radius: 15px;
                    min-width: 12vw;
                  ">
                    <div>
                      <img :src="`${$baseURL}${item.chart_icon[0]}`" height="70" />
                    </div>
                    <div class="fw-bold mt-1">
                      <div>{{ item.sensor[0][0] }}</div>
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ formatDate(card[0].chart.chart_date) }},
                      {{ formatTime(card[0].chart.chart_date) }}
                    </div>
                  </div>
                  <div style="color: #46dddd">
                    <p class="p-0 m-0">
                      <span class="fw-bold mx-1" style="font-size: 2rem">{{
                        item.chart_data[0].slice(-1)[0]
                      }}</span>
                      <span style="font-size: 1rem">{{ item.symbol[0][0] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stations[1][0].chart.chart_sensor.flat().length > 3">
          <div v-for="card in [stations[1]]" :key="card.id">
            <div>
              <carousel :perPage="3" :autoplay="true" :navigationEnabled="true" :loop="true" :autoplayHoverPause="true"
                :autoplayTimeout="5000" :paginationEnabled="false"
                navigationNextLabel="<i class='zmdi zmdi-chevron-right zmdi-hc-lg'></i>"
                navigationPrevLabel="<i class='zmdi zmdi-chevron-left zmdi-hc-lg'></i>">
                <slide v-for="(item, index) in chart_f2(
                      card[0].chart.mix_status,
                      card[0].chart.chart_sensor,
                      card[0].chart.array_act_chart_type,
                      card[0].chart.chart_label,
                      card[0].chart.chart_data,
                      card[0].chart.array_act_icon,
                      card[0].chart.array_act_symbol
                    )" :key="'C' + index" class="my-2 d-flex justify-content-around">
                  <div class="text-center comShadow" style="background: #fff; border-radius: 15px; max-width: 12vw">
                    <div class="text-white p-2" style="
                      background: #46dddd;
                      border-radius: 15px;
                      min-width: 12vw;
                    ">
                      <div>
                        <img :src="`${$baseURL}${item.chart_icon[0]}`" height="70" />
                      </div>
                      <div class="fw-bold mt-1">
                        <div>{{ item.sensor[0][0] }}</div>
                      </div>
                      <div style="font-size: 0.9rem">
                        {{ formatDate(card[0].chart.chart_date) }},
                        {{ formatTime(card[0].chart.chart_date) }}
                      </div>
                    </div>
                    <div style="color: #46dddd">
                      <p class="p-0 m-0">
                        <span class="fw-bold mx-1" style="font-size: 2rem">{{
                          item.chart_data[0].slice(-1)[0]
                        }}</span>
                        <span style="font-size: 1rem">{{
                          item.symbol[0][0]
                        }}</span>
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>

      <div v-if="stations[1][0].chart.mix_status[0]">
        <div v-if="stations[1][0].chart.chart_sensor.flat().length == 2">
          <div class="d-flex flex-wrap justify-content-around">
            <div v-for="(sensorData, index) in stations[1][0].chart.chart_data" :key="index">
              <div class="my-2">
                <div class="text-center comShadow mx-1" style="background: #fff; border-radius: 15px; min-width: 19vw;">
                  <div class="text-white p-2" style="background: #46dddd; border-radius: 15px;">
                    <div>
                      <img :src="`${$baseURL}${stations[1][0].chart.array_act_icon[0][index][0]}`" height="70" />
                    </div>
                    <div class="fw-bold mt-1">
                      <div>{{ stations[1][0].chart.chart_sensor[0][index][0] }}</div>
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ formatDate(stations[1][0].chart.chart_date) }}, {{ formatTime(stations[1][0].chart.chart_date)
                      }}
                    </div>
                  </div>
                  <div style="color: #46dddd">
                    <p class="p-0 m-0">
                      <span class="fw-bold mx-1" style="font-size: 2rem">{{ getLastData(sensorData[0]) }}</span>
                      <span style="font-size: 1rem">{{ stations[1][0].chart.array_act_symbol[0][index][0] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stations[1][0].chart.chart_sensor.flat().length == 3">
          <div class="d-flex flex-wrap justify-content-around">
            <div v-for="(sensorData, index) in stations[1][0].chart.chart_data" :key="index">
              <div class="my-2">
                <div class="text-center comShadow mx-1" style="background: #fff; border-radius: 15px;">
                  <div class="text-white p-2" style="background: #46dddd; border-radius: 15px; min-width: 12vw;">
                    <div>
                      <img :src="`${$baseURL}${stations[1][0].chart.array_act_icon[0][index][0]}`" height="70" />
                    </div>
                    <div class="fw-bold mt-1">
                      <div>{{ stations[1][0].chart.chart_sensor[0][index][0] }}</div>
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ formatDate(stations[1][0].chart.chart_date) }}, {{ formatTime(stations[1][0].chart.chart_date)
                      }}
                    </div>
                  </div>
                  <div style="color: #46dddd">
                    <p class="p-0 m-0">
                      <span class="fw-bold mx-1" style="font-size: 2rem">{{ getLastData(sensorData[0]) }}</span>
                      <span style="font-size: 1rem">{{ stations[1][0].chart.array_act_symbol[0][index][0] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stations[1][0].chart.chart_sensor.flat().length > 3">
          <div v-for="card in [stations[1]]" :key="card.id">
            <div>
              <carousel :perPage="3" :autoplay="true" :navigationEnabled="true" :loop="true" :autoplayHoverPause="true"
                :autoplayTimeout="5000" :paginationEnabled="false"
                navigationNextLabel="<i class='zmdi zmdi-chevron-right zmdi-hc-lg'></i>"
                navigationPrevLabel="<i class='zmdi zmdi-chevron-left zmdi-hc-lg'></i>">
                <slide v-for="(sensorData, index) in stations[1][0].chart.chart_data" :key="index"
                  class="my-2 d-flex justify-content-around">
                  <div class="text-center comShadow" style="background: #fff; border-radius: 15px; max-width: 12vw">
                    <div class="text-white p-2" style="
                      background: #46dddd;
                      border-radius: 15px;
                      min-width: 12vw;
                    ">
                      <div>
                        <img :src="`${$baseURL}${stations[1][0].chart.array_act_icon[0][index][0]}`" height="70" />
                      </div>
                      <div class="fw-bold mt-1">
                        <div>{{ stations[1][0].chart.chart_sensor[0][index][0] }}</div>
                      </div>
                      <div style="font-size: 0.9rem">
                        {{ formatDate(stations[1][0].chart.chart_date) }}, {{ formatTime(stations[1][0].chart.chart_date)
                        }}
                      </div>
                    </div>
                    <div style="color: #46dddd">
                      <p class="p-0 m-0">
                        <span class="fw-bold mx-1" style="font-size: 2rem">{{ getLastData(sensorData[0]) }}</span>
                        <span style="font-size: 1rem">{{ stations[1][0].chart.array_act_symbol[0][index][0] }}</span>
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CHART -->
    <div>
      <div v-for="(card, index) in stations[1]" :key="index">
        <div v-for="(item, index) in chart_f2(
          card.chart.mix_status,
          card.chart.chart_sensor,
          card.chart.array_act_chart_type,
          card.chart.chart_label,
          card.chart.chart_data,
          card.chart.array_act_icon,
          card.chart.array_act_symbol
        )" :key="index">

          <div v-if="item.mix">
            <div class="box-sm border mt-2 mx-auto bg-white" style="border-radius: 15px">
              <div>
                <div v-if="balai == 6">
                  <div>
                    <div class="px-2 pt-1">
                      Data {{ stations[1][0].chart.chart_sensor[0][0][0] }} dan {{
                        stations[1][0].chart.chart_sensor[0][1][0] }}
                    </div>
                  </div>
                  <div v-if="stations[1][0].chart.chart_sensor[0].length == 2">
                    <Chart v-if="weatherData1.length > 0 && weatherData1.length > 0 && timeLabel.length > 0"
                      style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="timeLabel" :chart-data="weatherData1"
                      :chart-data2="weatherData2"
                      :title1="`${stations[1][0].chart.chart_sensor[0][0]} (${stations[1][0].chart.array_act_symbol[0][0][0]})`"
                      :title2="`${stations[1][0].chart.chart_sensor[0][1]} (${stations[1][0].chart.array_act_symbol[0][1][0]})`"
                      :type1="stations[1][0].chart.array_act_chart_type[0][0]"
                      :type2="stations[1][0].chart.array_act_chart_type[0][1]" is="MixChartFiltered"></Chart>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <p class="px-2 pt-1">
                      Data {{ stations[1][0].chart.chart_sensor[0][0][0] }} dan {{
                        stations[1][0].chart.chart_sensor[0][1][0] }} 24 Jam Terakhir
                    </p>
                  </div>
                  <div v-if="stations[1][0].chart.chart_sensor[0].length == 2">
                    <Chart style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="stations[1][0].chart.chart_label[0][0]"
                      :chart-data="stations[1][0].chart.chart_data[0][0]"
                      :chart-data2="stations[1][0].chart.chart_data[1][0]"
                      :title1="`${stations[1][0].chart.chart_sensor[0][0]} (${stations[1][0].chart.array_act_symbol[0][0][0]})`"
                      :title2="`${stations[1][0].chart.chart_sensor[0][1]} (${stations[1][0].chart.array_act_symbol[0][1][0]})`"
                      :type1="stations[1][0].chart.array_act_chart_type[0][0]"
                      :type2="stations[1][0].chart.array_act_chart_type[0][1]" is="MixChart"></Chart>
                  </div>
                  <div v-if="stations[1][0].chart.chart_sensor[0].length == 3">
                    <Chart style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="stations[1][0].chart.chart_label[0][0]"
                      :chart-data="stations[1][0].chart.chart_data[0][0]"
                      :chart-data2="stations[1][0].chart.chart_data[1][0]"
                      :chart-data3="stations[1][0].chart.chart_data[2][0]"
                      :title1="`${stations[1][0].chart.chart_sensor[0][0]} (${stations[1][0].chart.array_act_symbol[0][0][0]})`"
                      :title2="`${stations[1][0].chart.chart_sensor[0][1]} (${stations[1][0].chart.array_act_symbol[0][1][0]})`"
                      :title3="`${stations[1][0].chart.chart_sensor[0][2]} (${stations[1][0].chart.array_act_symbol[0][2][0]})`"
                      :type1="stations[1][0].chart.array_act_chart_type[0][0]"
                      :type2="stations[1][0].chart.array_act_chart_type[0][1]"
                      :type3="stations[1][0].chart.array_act_chart_type[0][2]" is="MixChart"></Chart>
                  </div>

                  <div class="row text-secondary" style="font-size: 0.8rem">
                    <div class="col-sm-6">
                      <p class="float-start mx-2">
                        {{ formatPrevDate(stations[1][0].chart.chart_date) }}
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <p class="float-end mx-1">
                        {{ formatDate(stations[1][0].chart.chart_date) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!item.mix" class="comShadow" style="border-radius: 15px">
            <!-- <div v-if="balai == 6">
              <div>
                <div class="px-2 pt-1">
                  Data {{ stations[1][0].chart.chart_sensor[0][0][0] }} dan {{
                    stations[1][0].chart.chart_sensor[0][1][0] }}
                </div>
              </div>
              <div v-if="stations[1][0].chart.chart_sensor[0].length == 2">
                <Chart v-if="weatherData1.length > 0 && weatherData1.length > 0 && timeLabel.length > 0"
                  style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="timeLabel" :chart-data="weatherData1"
                  :chart-data2="weatherData2"
                  :title1="`${stations[1][0].chart.chart_sensor[0][0]} (${stations[1][0].chart.array_act_symbol[0][0][0]})`"
                  :title2="`${stations[1][0].chart.chart_sensor[0][1]} (${stations[1][0].chart.array_act_symbol[0][1][0]})`"
                  :type1="stations[1][0].chart.array_act_chart_type[0][0]"
                  :type2="stations[1][0].chart.array_act_chart_type[0][1]" is="MixChartFiltered"></Chart>
              </div>
            </div> -->
            <div v-if="item.chart_type[0] == 'BarChart' || item.chart_type[0] == 'LineChart'
              " class="box-sm border rounded mx-auto bg-white mb-2" style="border-radius: 15px">

              <div class="rounded" :class="{ 'darkMode': isDarkMode }">


                <div v-if="balai === 9">
                  <div>
                    <p class="px-2 pt-1 ">
                      Data {{ item.sensor[0][0] }}
                    </p>
                  </div>
                  <Chart v-if="weatherData1.length > 0 && weatherData1.length > 0 && timeLabel.length > 0"
                    style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="timeLabel" :chart-data="weatherData1"
                    :title1="`${stations[1][0].chart.chart_sensor[0][0]} (${stations[1][0].chart.array_act_symbol[0][0][0]})`"
                    :type1="stations[1][0].chart.array_act_chart_type[0][0]" is="LineChartFiltered"></Chart>
                </div>
                <div v-else>
                  <div>
                    <p class="px-2 pt-1 ">
                      Data {{ item.sensor[0][0] }} 24 Jam Terakhir
                    </p>
                  </div>
                  <Chart style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="item.chart_label[0]"
                    :chart-data="item.chart_data[0]" :title="`${item.sensor[0]} (${item.symbol[0][0]})`"
                    :is="item.chart_type[0][0]"></Chart>
                </div>



                <div v-if="balai !== 9" class="row text-secondary pb-1" style="font-size: 0.8rem">
                  <div class="col-sm-6">
                    <span class="float-start" style="margin-top: -10px; margin-left: 25px"
                      :class="{ 'darkMode': isDarkMode }">
                      {{ formatPrevDate(card.chart.chart_date) }}
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <span class="float-end mx-1" style="margin-top: -10px" :class="{ 'darkMode': isDarkMode }">
                      {{ formatDate(card.chart.chart_date) }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- <div v-if="stations[0].station_type === 3">
                <div>
                  <div class="px-2 pt-1">
                    Data Cuaca 24 Jam Terakhir
                  </div>
                </div>

                <Chart style="height: 28vh" class="p-0 pr-0 pt-0 pb-0"
                  :label="item.chart_label[0]" :chart-data="stations[1][0].chart.chart_data[2][0]"
                  :chart-data2="stations[1][0].chart.chart_data[3][0]"
                  :chart-data3="stations[1][0].chart.chart_data[4][0]"
                  :chart-data4="stations[1][0].chart.chart_data[5][0]"
                  :chart-data5="stations[1][0].chart.chart_data[6][0]"
                  :chart-data6="stations[1][0].chart.chart_data[7][0]"
                  :title1="`${stations[1][0].chart.chart_sensor[2][0]} (${stations[1][0].chart.array_act_symbol[2][0]})`"
                  :title2="`${stations[1][0].chart.chart_sensor[3][0]} (${stations[1][0].chart.array_act_symbol[3][0]})`"
                  :title3="`${stations[1][0].chart.chart_sensor[4][0]} (${stations[1][0].chart.array_act_symbol[4][0]})`"
                  :title4="`${stations[1][0].chart.chart_sensor[5][0]} (${stations[1][0].chart.array_act_symbol[5][0]})`"
                  :title5="`${stations[1][0].chart.chart_sensor[6][0]} (${stations[1][0].chart.array_act_symbol[6][0]})`"
                  :title6="`${stations[1][0].chart.chart_sensor[7][0]} (${stations[1][0].chart.array_act_symbol[7][0]})`"
                  is="AWSChart"></Chart>
           
                <div class="row text-secondary pb-1" style="font-size: 0.8rem">
                  <div class="col-sm-6">
                    <span class="float-start" style="margin-top: -10px; margin-left: 5rem">
                      {{ formatPrevDate(card.chart.chart_date) }}
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <span class="float-end mx-1" style="margin-top: -10px; padding-right: 4rem">
                      {{ formatDate(card.chart.chart_date) }}
                    </span>
                  </div>
                </div>
              </div> -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AWSChart from "@/components/Chart/AWSChart";
import BarChart from "@/components/Chart/BarChart";
import LineChart from "@/components/Chart/LineChart";
import LineChartFiltered from "@/components/Chart/LineChartFiltered";
import MixChart from "@/components/Chart/MixChart";
import MixChartFiltered from "@/components/Chart/MixChartFiltered";
import mtc_i from "@/assets/icons/map/mtc.svg";
import ok_i from "@/assets/icons/map/ok.svg";
import s1_i from "@/assets/icons/map/s1.svg";
import s2_i from "@/assets/icons/map/s2.svg";
import s3_i from "@/assets/icons/map/s3.svg";
// import axios from 'axios';

export default {
  name: "Slider",
  props: ["stations", "status", "ava_width", "filteredData"],
  watch: {
    filteredData: {
      handler(newFilteredData) {
        this.weatherData1 = newFilteredData.map(item => item.weather_data[0]);
        this.weatherData2 = newFilteredData.map(item => item.weather_data[1]);

        this.timeLabel = newFilteredData.map(item => {
          const dateObject = new Date(item.waktu);
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          return `${hours}:${formattedMinutes}`;
        });
      },
      immediate: true, // Trigger the handler immediately with the current value
    },
  },
  computed: {
    weatherData1: {
      get() {
        return this.filteredData.map(item => item.weather_data[0]).reverse();
      },
      set() {
        // Empty setter to satisfy Vue's requirements
      },
    },
    weatherData2: {
      get() {
        return this.filteredData.map(item => item.weather_data[1]).reverse();
      },
      set() {
        // Empty setter to satisfy Vue's requirements
      },
    },
    timeLabel: {
      get() {
        return this.filteredData.map(item => item.waktu).reverse();
      },
      set() {
        // Empty setter to satisfy Vue's requirements
      },
    },

    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  components: {
    AWSChart,
    BarChart,
    LineChart,
    LineChartFiltered,
    MixChart,
    MixChartFiltered,
  },
  data() {
    return {
      mtc_i,
      ok_i,
      s1_i,
      s2_i,
      s3_i,
      total_s: null,
      jsonData: null,
    };
  },
  methods: {
    getNote(sensorData) {
      // Parse the string into an array
      const noteArray = JSON.parse(sensorData);

      // Return the last element of the array
      return noteArray;
    },
    getLastData(sensorData) {
      return sensorData[sensorData.length - 1];
    },
    formatDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var d = new Date(date),
        year = d.getFullYear(),
        month = "" + d.getMonth(),
        day = "" + d.getDate();

      if (day.length < 2) day = "0" + day;
      return [day, monthShortNames[month], year].join(" ");
    },
    formatTime(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = d.getSeconds();

      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;
      if (second.length < 2) second = "0" + second;

      return [hour, minute].join(":");
    },
    formatPrevDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var days = -1;

      var d = new Date(date);

      var d_1 = new Date(d.getTime() + days * 24 * 60 * 60 * 1000),
        day_1 = "" + d_1.getDate(),
        month_1 = "" + d_1.getMonth(),
        year_1 = d_1.getFullYear();

      if (day_1.length < 2) day_1 = "0" + day_1;

      return [day_1, monthShortNames[month_1], year_1].join(" ");
    },
    cards_f(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          label: card,
          icon: b[i],
          symbol: c[i],
          data: d[i],
          chart_type: e[i],
        };
      });
    },
    chart_f(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          label: card,
          chart_type: b[i],
          data: c[i],
          sensor: d[i],
          date: e[i],
        };
      });
    },

    chart_f2(a, b, c, d, e, g, h) {
      return a.map((card, i) => {
        return {
          mix: card,
          sensor: b[i],
          chart_type: c[i],
          chart_label: d[i],
          chart_data: e[i],

          chart_icon: g[i],
          symbol: h[i],
        };
      });
    },
    chart_f3(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          mix: card,
          sensor: b[i],
          chart_type: c[i],
          chart_label: d[i],
          chart_data: e[i],
        };
      });
    },
  },

  created() {
    this.gAuthStation();
    var x = this.stations[1][0].chart.chart_sensor;
    this.total_s = x.flat().length;
    // axios.get(`http://localhost:8080/test_station.json`)
    //   .then(response => {
    //     // Handle successful response
    //     this.jsonData = response.data
    //     // console.log(this.jsonData);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error);
    //   });

  },

  mounted() {
    // this.ava_width = screen.availWidth;
  },
};
</script>

<style scoped>
.data-font {
  font-size: 2rem;
}

.symbol-font {
  font-size: 1.2rem;
}

.title-font {
  font-size: 1.1rem;
}

.root.padding {
  padding: 0;
}

.flex {
  display: flex;
  /* gap: 0.5rem; */
  flex-wrap: wrap;
}

.steps-wrapper {
  background-color: #232323;
}

.img-awlr {
  height: 150px;
}

@media only screen and (max-width: 768px) {
  .flex>div {
    flex: 100%;
  }
}

ul {
  list-style: none;
  display: table;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  padding-right: 1em;
}

.statSZ {
  height: 22px;
  margin-right: 10px;
}

.comShadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.iconSZ img {
  resize: both;
  height: 13vh;
  max-height: 13vh;
}

.img-normal {
  height: 160px;
  width: 190px;
}

/* Add your desired styles for the label and value containers */
.info-label {
  font-weight: 500;
  font-size: 1rem;
}

.info-value {
  margin-left: 10px;
  font-size: 1rem;
}

/* Dummy element style to hide if needed */
.info-dummy {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0);
  font-size: 1rem;
}

.imgSZ img {
  resize: both;
  height: 180px;
  width: 190px;
}

@media (max-width: 768px) {
  .imgSZ {
    height: 170px;
    /* You can adjust the height as needed */
  }

  .info-dummy {
    display: none;
  }
}
</style>