<script>
import { Line } from "vue-chartjs";

export default {
  name: "AWSChart",
  extends: Line,
  props: {
    label: Array,
    chartData: Array,
    chartData2: Array,
    chartData3: Array,
    chartData4: Array,
    // chartData5: Array,
    // chartData6: Array,
    legend: Boolean,
    title1: String,
    title2: String,
    title3: String,
    title4: String,
    // title5: String,
    // title6: String,
  },
  data() {
    return {
      mixtypes: Array(4).fill(""),
    };
  },
  mounted() {
    this.configureMixtypes();

    const datasets = [
      this.createDataset(this.title1, 'line', this.chartData, "Suhu Udara", "purple"),
      this.createDataset(this.title2, 'line', this.chartData2, "Kelembaban Udara", "orange"),
      this.createDataset(this.title3, 'line', this.chartData3, "Tekanan Udara", "darkcyan"),
      this.createDataset(this.title4, 'line', this.chartData4, "Radiasi Matahari", "darkred"),
      // this.createDataset(this.title5, 'line', this.chartData5, "Evaporasi", "blueviolet"),
      // this.createDataset(this.title6, 'bar', this.chartData6, "Curah Hujan", "rgba(0, 191, 255, 0.5)"),
    ];

    this.renderChart(
      {
        labels: this.label,
        datasets: datasets,
      },
      this.getChartOptions()
    );
  },
  methods: {
    configureMixtypes() {
      const chartTypes = ["LineChart", "BarChart"];
      // this.mixtypes = [this.type1, this.type2, this.type3, this.type4, this.type5, this.type6].map((type) =>
      this.mixtypes = [this.type1, this.type2, this.type3, this.type4].map((type) =>
        chartTypes.includes(type) ? "line" : "bar"
      );
    },
    createDataset(label, type, data, yAxisID, borderColor) {
      return {
        label: label,
        type: type,
        yAxisID: yAxisID,
        borderColor: borderColor,
        pointBackgroundColor: borderColor,
        borderWidth: 2,
        pointBorderColor: "white",
        data: data,
        pointRadius: (type === "line" && 0) || undefined,
        lineTension: (type === "line" && 0) || undefined,
        fill: type === 'line' ? false : undefined,
        backgroundColor: type === 'bar' ? borderColor : undefined,
      };
    },
    getChartOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      align: 'start',
      
      labels: {
        boxWidth: 12, 
        generateLabels: function(chart) {
          return chart.data.datasets.map(function(dataset, index) {
            return {
              text: '', // Empty text
              fillStyle: dataset.borderColor,
              hidden: dataset.hidden,
              lineCap: dataset.borderCapStyle,
              lineDash: dataset.borderDash,
              lineDashOffset: dataset.borderDashOffset,
              lineJoin: dataset.borderJoinStyle,
              lineWidth: dataset.borderWidth,
              strokeStyle: dataset.borderColor,
              pointStyle: dataset.pointStyle,
              datasetIndex: index
            };
          });
        }
      }
    },
    scales: {
      yAxes: this.getYAxes(),
      xAxes: this.getXAxes(),
    },
  };
},

    getYAxes() {
      const yAxisIDs = ["Suhu Udara", "Kelembaban Udara", "Tekanan Udara", "Radiasi Matahari"];
      return yAxisIDs.map((id, index) => ({
        id,
        position: ["left", "left", "right", "right"][index],
        ticks: { beginAtZero: [].includes(id) },
        gridLines: { color: "rgba(151,151,151,0.2)" },
      }));
    },

    getXAxes() {
      return [
        {
          ticks: {
            display: true,
            callback: (value, index, values) =>
              index === 0 || index === values.length - 1 ? value : "",
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            fontColor: "#6c757d",
          },
          gridLines: { color: false },
        },
      ];
    },
  },
};
</script>
