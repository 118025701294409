<template>
  <div class="row">
    <div id="map" ref="map" :style="{ height: mapHeight, width: mapWidth }"></div>
    <small v-if="isControl" class="d-flex justify-content-end py-1">
      <img @click="refreshPage" :src="mreload_im" width="15" style="cursor: pointer;" />
    </small>
    <small id="loading-icon" v-show="isLoading">
      Loading GeoServer data...
    </small>
    <small class="d-flex justify-content-between py-1" v-if="errorMessages.length > 0">
      <b style="color: red;">Failed to load Geoserver data:</b>
      <span id="errorMessagesTemplate">
        {{ errorMessages.join(', ') }}
      </span>
      <img @click="reloadMarkers" :src="mreload_im" width="15" style="cursor: pointer;" />
    </small>
  </div>
</template>

<script>
import './lib/L.Geoserver.js';
import './src/leaflet-search.js';
import './src/leaflet-search.css';
import L from "leaflet";
import axios from "axios";
import awlr_im from "@/assets/icons/map/mawlr.svg";
import water_im from "@/assets/icons/map/mwater.svg";
// import guser_im from "@/assets/icons/map/muser.svg";
// import gpipe_im from "@/assets/icons/map/mpipe.svg";
import mreload_im from "@/assets/icons/map/mreload.svg";

import awlr_i from "@/assets/icons/map/awlr.svg";
// import us_states from "./data/us-states.js";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
// import 'leaflet-search';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

export default {
  data() {
    return {
      role: null,
      balai: null,
      stations: [],
      loading_i: true,
      status: [],
      user_id: null,
      center: undefined,
      isLoading: false,
      errorMessages: [],
      geo_jaringan_pipa: {},
      geo_sumber_air_baku: {},
      geo_new_aksesoris: {},
      geo_wm_pelanggan: {},
      map: null,
      mapHeight: '51vh',
      mapWidth: '100%',
      isFullscreen: false,
      poiLayers: null,
      jarLayers: null,
      sites: null,
      layerControl: null,
      searchControl: null,
      searchJaringan: null,
      mreload_im,
      geojsonOpts: null,
      isControl: false,
      detBalai: null
    };
  },
  async created() {
    let user = localStorage.getItem("user-info") || {};

    try {
      if (typeof user === "object") {
        this.balai = this.$fixedBalai;

        const response = await axios.get(`${this.$baseURL}/balai/non-auth/${this.balai}`);
        this.detBalai = response.data[0];
      } else if (typeof user === "string") {
        this.role = JSON.parse(user).profile.role;
        this.user_id = JSON.parse(user).profile.user.id;
        this.balai = JSON.parse(user).profile.balai.id;
        this.token = JSON.parse(user).token;

        const response = await axios.get(`${this.$baseURL}/balai/${this.balai}`, {
          headers: {
            Authorization: `Token ${this.token}`,
          },
        });
        this.detBalai = response.data;
      }

      this.zoom = this.detBalai && this.detBalai.map_zoom ? this.detBalai.map_zoom : 10;
      this.center = [this.detBalai.latitude, this.detBalai.longitude];

      // console.log("this.detBalai in created hook:", this.detBalai);
      // Once the asynchronous requests are complete and this.detBalai is set, call any additional functions or methods
      // this.handleMountedLogic();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async mounted() {
    // console.log("this.detBalai in mounted hook:", this.detBalai);
    const wom = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
      maxZoom: 18,
    });

    const mlabel = L.tileLayer("https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.{ext}", {
      maxZoom: 18,
      ext: 'png'
    });

    this.sites = L.layerGroup();
    this.map = L.map('map', {
      center: [-8.02535424, 112.638955658],
      // center: [this.detBalai.latitude, this.detBalai.longitude],
      zoom: (this.detBalai && this.detBalai.map_zoom) ? this.detBalai.map_zoom : 10,

      layers: [this.sites, wom, mlabel],
      // layers: [osm, this.sites, wom],
      zoomControl: false,
      attributionControl: false,
      // fullscreenControl: false,  // Disable fullscreen by default
    })

    L.control
      .fullscreen({
        position: 'bottomright',
        title: 'Show me the fullscreen !',
        titleCancel: 'Exit fullscreen mode',
        content: null,
        forceSeparateButton: true,
        forcePseudoFullscreen: true,
        fullscreenElement: false
      })
      .addTo(this.map);

    this.map.on('enterFullscreen', function () {
      console.log('entered fullscreen');
    });

    this.map.on('exitFullscreen', function () {
      console.log('exited fullscreen');
    });

    this.poiLayers = L.layerGroup().addTo(this.map);
    // this.jarLayers = L.layerGroup().addTo(this.map);

    if (this.balai) {
      await this.initMap();
      // this.addCustomButton();
      this.createLayerControl();
      await this.setupSearchControl();
      // await this.setupSearchJaringan();

      var customGeocoder = L.Control.Geocoder.nominatim({
        geocodingQueryParams: {
          countrycodes: 'ID'
        }
      });
      L.Control.geocoder({
        geocoder: customGeocoder
      }).addTo(this.map);
    }


  },
  methods: {

    async initMap() {
      await axios
        .get(`${this.$baseURL}/home-data/non-auth/${this.balai}`)
        .then((r) => {
          this.stations = r.data;

          if (r.status == 200) {
            this.loading_i = false;
          }
        });

      this.stations.forEach((station) => {
        const popupContent = `
        <div class="my-custom-popup">
            <div class="text-center"><img src="${awlr_i}" style="height: 38px;" /></div>
            <div class="my-1" style="font-weight: 600">${station[0].station_name} <br /></div>
            <div class="my-1 text-muted" style="font-size: 0.7rem">${this.formatDate(station[1].table.date)}, ${this.formatTime(station[1].table.date)}</div>

            <a
              href="/station/data/${station[0].id}/${this.user_id}" 
              class="btn btn-primary d-flex flex-row justify-content-center py-0"
              style="margin-right: 2.5px"
            >
              <span style="font-size: 0.8rem" class="text-white">Data</span>
            </a>
          </div>
        `;
        const markerIconSize = [20, 25];

        L.marker([station[0].latitude, station[0].longitude])
          .bindPopup(popupContent)
          .setIcon(L.icon({
            iconUrl: awlr_im,
            iconSize: markerIconSize
          }))
          .addTo(this.sites);
      });

      //GEOJSON SEARCH
      const typeNames = [
        'perumdatirkan:sumber_air_baku',
        // 'perumdatirkan:wm_pelanggan',
        // 'perumdatirkan:new_aksesoris',
        // 'perumdatirkan:jaringan_pipa',

      ];
      const requests = typeNames.map(typeName => {
        return axios.get('https://weatherapp.blitztechnology.tech/geoserver/perumdatirkan/ows', {
          params: {
            service: 'WFS',
            version: '1.0.0',
            request: 'GetFeature',
            typeName: typeName,
            maxFeatures: 1000,
            // maxFeatures: 500000,
            outputFormat: 'application/json'
          }
        });
      });

      this.isLoading = true;

      var sab_icon = L.divIcon({
        className: 'custom-svg-icon',
        html: `<div><img src="${water_im}" width="15" height="20"></div>`,
        iconSize: [17, 20],
        iconAnchor: [8, 20],
      });

      // var wp_icon = L.divIcon({
      //   className: 'custom-svg-icon',
      //   html: `<div><img src="${guser_im}" width="15" height="20"></div>`,
      //   iconSize: [17, 20],
      //   iconAnchor: [8, 20],
      // });

      // var na_icon = L.divIcon({
      //   className: 'custom-svg-icon',
      //   html: `<div><img src="${gpipe_im}" width="15" height="20"></div>`,
      //   iconSize: [17, 20],
      //   iconAnchor: [8, 20],
      // });


      this.geojsonOpts = {
        onEachFeature: function (feature, marker) {
          let tableContent = '<table>';
          // if (feature.properties.foto !== null) {
          //   tableContent += '<div style="text-align: center;">';
          //   tableContent += `<img src="${feature.properties.foto}" alt="Image" style="max-width: 100px;">`;
          //   tableContent += '</div>';
          // }
          for (const key in feature.properties) {
            if (Object.prototype.hasOwnProperty.call(feature.properties, key)) {
              tableContent += `<tr><td>${key}</td><td>${feature.properties[key]}</td></tr>`;
            }
          }
          tableContent += '</table>';



          marker.bindPopup(`${tableContent}`);
        },

        pointToLayer: function (feature, latlng) {
          const layerName = feature.id.split('.')[0];
          if (layerName === 'sumber_air_baku') {
            return L.marker(latlng, { icon: sab_icon });
          }
          // if (layerName === 'wm_pelanggan') {
          //   return L.marker(latlng, { icon: wp_icon });
          // }
          // if (layerName === 'new_aksesoris') {
          //   return L.marker(latlng, { icon: na_icon });
          // }
          else {
            return L.marker(latlng);
          }
        }
      }

      try {
        const responses = await Promise.all(requests);

        responses.forEach((response, index) => {
          const typeName = typeNames[index];
          if (typeName === 'perumdatirkan:sumber_air_baku') {
            this.geo_sumber_air_baku = response.data;
          }
          // if (typeName === 'perumdatirkan:wm_pelanggan') {
          //   this.geo_wm_pelanggan = response.data;
          // }
          // if (typeName === 'perumdatirkan:new_aksesoris') {
          //   this.geo_new_aksesoris = response.data;
          // }
          // if (typeName === 'perumdatirkan:jaringan_pipa') {
          //   this.geo_jaringan_pipa = response.data;
          // }
        });

      } catch (error) {
        console.error('Error loading GeoJSON data:', error);
      } finally {

        this.isLoading = false;
      }
      this.errorMessages = [];

      var sumber_air_baku_layer = this.createLayer.call(this, this.geo_sumber_air_baku, 'sumber_air_baku');
      // var wm_pelanggan_layer = this.createLayer.call(this, this.geo_wm_pelanggan, 'wm_pelanggan');
      // var new_aksesoris_layer = this.createLayer.call(this, this.geo_new_aksesoris, 'new_aksesoris');
      // var jaringan_pipa_layer = this.createLayer.call(this, this.geo_jaringan_pipa, 'jaringan_pipa');

      this.poiLayers = L.layerGroup([
        sumber_air_baku_layer,
        // wm_pelanggan_layer,
        // new_aksesoris_layer
      ]).addTo(this.map);

      // this.jarLayers = L.layerGroup([
      //   jaringan_pipa_layer
      // ]).addTo(this.map);

    },
    createLayer(geoData, layerName) {
      try {
        if (geoData && typeof geoData === 'object') {
          return L.geoJson(geoData, this.geojsonOpts);
        } else {
          const errorMessage = `Error creating ${layerName} layer. Data is not of type 'object'.`;
          this.errorMessages.push(layerName);
          this.handleErrorClick(layerName, errorMessage);

        }
      } catch (error) {
        const errorMessage = `Error creating ${layerName} layer: ${error.message}`;
        this.errorMessages.push(layerName);
        this.handleErrorClick(layerName, errorMessage);
      }

      return null;

    },
    handleErrorClick(layerName) {
      const errorMessagesTemplate = document.getElementById('errorMessagesTemplate');
      errorMessagesTemplate.innerHTML = '';

      const baseGeoServerUrl = 'https://weatherapp.blitztechnology.tech/geoserver/perumdatirkan/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=perumdatirkan:';

      if (layerName === 'sumber_air_baku') {
        const link = document.createElement('a');
        link.href = `${baseGeoServerUrl}${layerName}&maxFeatures=500&outputFormat=application%2Fjson`;
        link.target = '_blank';
        link.textContent = this.errorMessages;

        errorMessagesTemplate.appendChild(link);
      } else {
        console.error(`URL not defined for layerName: ${layerName}`);
      }
    },

    createLayerControl() {
      const layerDefinitions = [
        // { name: 'jaringan_pipa', title: 'Jaringan Pipa' },
        { name: 'new_jaringan_pipa', title: 'Jaringan Pipa' },
        { name: 'sumber_air_baku', title: 'Sumber Air Baku' },
        { name: 'kec_kab_malang', title: 'Kec/Kab Malang' },
        { name: 'wm_pelanggan', title: 'WM Pelanggan' },
        { name: 'new_aksesoris', title: 'New Aksesoris' }
      ];

      const overlays = {};

      layerDefinitions.forEach(layerDef => {
        const layer = L.Geoserver.wms("https://weatherapp.blitztechnology.tech/geoserver/wms", {
          layers: `perumdatirkan:${layerDef.name}`,
        });

        overlays[layerDef.title] = layer;
      });


      overlays['Stasiun'] = this.sites;
      overlays['Marker Air Baku'] = this.poiLayers;
      // overlays['jaringan marker'] = this.jarLayers;


      const newAksesorisLegendUrl = "https://weatherapp.blitztechnology.tech/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=perumdatirkan:new_aksesoris";
      const newAksesorisLegend = L.imageOverlay(newAksesorisLegendUrl, [], { opacity: 0.7, position: 'bottomleft' });
      overlays['new_aksesorisLegend'] = newAksesorisLegend;

      // OPTION 2
      // const legendControl = L.control({ position: 'bottomleft' });
      // legendControl.onAdd = function () {
      //   const div = L.DomUtil.create('div', 'legend');
      //   div.innerHTML = '<img src="' + newAksesorisLegendUrl + '" width="85" height="260">';
      //   return div;
      // };
      // legendControl.addTo(this.map);

      overlays['Jaringan Pipa'].addTo(this.map);

      this.layerControl = L.control.layers(null, overlays).addTo(this.map);

      // JavaScript to change layer control text color programmatically
      const overlayLabels = document.querySelectorAll('.leaflet-control-layers-overlays label span');

      overlayLabels.forEach(label => {
        label.style.color = 'black'; // Change to the desired text color
      });
    },

    setupSearchControl() {
      this.searchControl = new L.Control.Search({
        layer: this.poiLayers,
        propertyName: 'nama',
        marker: false,
        moveToLocation: function (latlng, title, map) {
          map.setView(latlng, 18);
        },
      });

      this.searchControl.on('search:locationfound', function (e) {

        if (e.layer._popup) e.layer.openPopup();
      }).on('search:collapsed', function () {

      });

      this.map.addControl(this.searchControl);
    },
    setupSearchJaringan() {
      this.searchJaringan = new L.Control.Search({
        layer: this.jarLayers,
        propertyName: 'jaringan',
        marker: false,
        moveToLocation: function (latlng, title, map) {
          map.setView(latlng, 18);
        },
      });

      this.searchJaringan.on('search:locationfound', function (e) {

        if (e.layer._popup) e.layer.openPopup();
      }).on('search:collapsed', function () {

      });

      this.map.addControl(this.searchJaringan);
    },
    async reloadMarkers() {
      this.poiLayers.clearLayers();
      // this.jarLayers.clearLayers();

      await this.initMap();

      if (this.searchControl && this.searchJaringan) {
        this.searchControl._layer = this.poiLayers;
        // this.searchJaringan._layer = this.jarLayers;
      } else {
        console.error("searchControl is not properly initialized.");
        this.isControl = true
      }
      

    },
    refreshPage() {
      window.location.reload();
    },

    formatDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var d = new Date(date),
        year = d.getFullYear(),
        month = "" + d.getMonth(),
        day = "" + d.getDate();

      if (day.length < 2) day = "0" + day;
      return [day, monthShortNames[month], year].join(" ");
    },
    formatTime(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = d.getSeconds();

      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;
      if (second.length < 2) second = "0" + second;

      return [hour, minute].join(":");
    },
    navigateToStationDetail(stationId) {
      console.log('Navigating to station detail:', stationId, this.balai);
      window.open(`/station/data/${stationId}/${this.balai}`, '_blank');
    },
  },
};
</script>


<style scoped>
#map {

  margin: 0;
  padding: 0;
  transition: height 0.5s, width 0.5s;
  border-radius: 10px;
}

.imgSZ::v-deep {
  height: 38px;
}
</style>